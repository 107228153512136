import React, { useEffect } from 'react';
import { Outlet, useSearchParams } from "react-router-dom";
import { ReactComponent as AirParkLogo } from 'assets/icons/ico_airpark_logo.svg'
import { useAuth0 } from '@auth0/auth0-react';

const DefaultLayout = () => {

    const { isLoading, isAuthenticated, loginWithRedirect, logout } = useAuth0();
    const [searchParams] = useSearchParams();
    const isIframeView = searchParams.get("iframe");

    useEffect(() => {
        if (isLoading === false && isAuthenticated === false) {
            loginWithRedirect({});
        }
    }, [isLoading, isAuthenticated, loginWithRedirect]);

    return (
        <div className="flex flex-col p-6">
            {
                !isIframeView &&
                    <div className="flex md:flex-row flex-col gap-y-3 justify-between items-center gap-x-6">
                        <div className="flex items-center gap-x-3">
                            <AirParkLogo className="h-11" />
                            <h1 className="text-3xl font-bold">AirPark KPI</h1>
                        </div>
                        <button className="h-11 rounded-lg bg-green-500 pl-6 pr-6 items-center justify-center font-medium text-white" onClick={() => {
                            logout({
                                returnTo: window.location.origin,
                            });
                        }}>Logout</button>
                    </div>
            }

            <div className="flex justify-center items-center">
                <Outlet />
            </div>
        </div>
    );
};

export default DefaultLayout;